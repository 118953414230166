import { useCallback, useEffect, useRef, useState } from "react"

export default function useAccordion(startAsOpen) {
	const [isOpen, setIsOpen] = useState(startAsOpen)
	const [elemHeight, setElemHeight] = useState(0)
	const ref = useRef(null)
	const initialHeight = useRef(isOpen ? `max-content` : `0px`)

	const toggleAccordion = useCallback(() => {
		setIsOpen(!isOpen)
	}, [setIsOpen, isOpen])

	useEffect(() => {
		function setElemHeightCallback() {
			const { current } = ref
			if (!current || current.scrollHeight === 0) {
				return requestAnimationFrame(setElemHeightCallback)
			}
			initialHeight.current = null
			setElemHeight(current.scrollHeight)
		}
		requestAnimationFrame(setElemHeightCallback)
		window.addEventListener("resize", setElemHeightCallback)
		return () => window.removeEventListener("resize", setElemHeightCallback)
	}, [])

	// if it starts as open, and it's the first render then initialHeight.current
	// is set to `max-content`, as we do not know the element's height yet.
	// this helps in SSR and first renders of components.
	// after the component mounts, initialHeight.current is set to null and the
	// elemHeight will be set correctly
	const height = isOpen ? initialHeight.current || `${elemHeight}px` : `0px`

	return { toggleAccordion, isOpen, height, ref }
}
