import React from "react"
import BlockContent from "./block-content"
import styles from "./schedule-day.module.css"
import Button from "./button"
import useAccordion from "../lib/use-accordion"

const ScheduleDay = ({ day, items = [] }) => {
	return (
		<>
			<ScheduleTitle day={day} />
			<section className={styles.root}>
				{items.length > 0 ? (
					<>
						<ScheduleHeading />
						{items.map(({ time, difficulty, classType, instructor, descriptionBlocks }, key) => (
							<ScheduleItem
								key={key}
								time={time}
								difficulty={difficulty}
								classType={classType}
								instructor={instructor}
								descriptionBlocks={descriptionBlocks}
							/>
						))}
					</>
				) : (
					<p className={styles.nothingScheduledMessage}>Nothing scheduled</p>
				)}
			</section>
		</>
	)
}

const ScheduleTitle = ({ day }) => <h3 className={styles.title}>{day}</h3>

const ScheduleHeading = () => (
	<div className={styles.rowHeader}>
		<p className={styles.rowHeaderItem}>Time</p>
		<p className={styles.rowHeaderItemClass}>Class</p>
	</div>
)

const ScheduleItem = ({ time, classType, instructor, difficulty, descriptionBlocks }) => {
	const { height, ref, isOpen, toggleAccordion } = useAccordion()

	return (
		<div className={styles.row}>
			<p className={styles.rowItem} time={time}>
				{time}
			</p>
			<p className={styles.rowItemClass}>{classType}</p>
			<div className={styles.rowItemDescription} ref={ref} style={{ height }}>
				<p className={styles.rowItemDescriptionItem}>
					<span className={styles.rowItemDescriptionSubheading}>Teacher</span>: {instructor}
				</p>
				<p className={styles.rowItemDescriptionItem}>
					<span className={styles.rowItemDescriptionSubheading}>Difficulty</span>: {difficulty}
				</p>
				{descriptionBlocks && (
					<div className={styles.rowItemDescriptionItem}>
						<BlockContent blocks={descriptionBlocks} />
					</div>
				)}
			</div>
			<div className={styles.rowItemButtonContainer}>
				<Button className={styles.button} onClick={toggleAccordion}>
					{isOpen ? `Less information` : `More information`}
				</Button>
			</div>
		</div>
	)
}

export default ScheduleDay
