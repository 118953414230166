import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment, useEffect, useRef, useState } from "react"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"
import smoothscroll from "smoothscroll-polyfill"
import Button from "../components/button"
import HeroImage from "../components/hero-image"
import ScheduleDay from "../components/schedule-day"
import SEO from "../components/seo"
import { cn } from "../lib/helpers"
import styles from "./index.module.css"

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

function getScheduleItemsOfDay(items, day) {
	return items.reduce((currentItems, currentItem) => {
		if (currentItem.day === day) {
			currentItems.push({
				time: currentItem.time,
				instructor: currentItem.class.teacher.name,
				difficulty: currentItem.class.level.name,
				classType: currentItem.class.name,
				descriptionBlocks: currentItem.class.descriptionBlocks,
			})
		}
		return currentItems
	}, [])
}

const IndexPage = (props) => {
	const data = useStaticQuery(graphql`
		query PageIndexQuery {
			groupSchedule: allSanitySchedule(filter: { _id: { eq: "groupSchedule" } }) {
				edges {
					node {
						amendments {
							day
							message
						}
						schedule {
							day
							time
							class {
								_id
								name
								level {
									name
									description {
										sanityChildren {
											text
										}
									}
								}
								teacher {
									name
								}
								descriptionBlocks: _rawDescription
							}
						}
					}
				}
			}
			site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
				title
				description
				landingPageImage {
					alt
					asset {
						mainImage: fluid(maxWidth: 1920) {
							src
							srcSet
							aspectRatio
							base64
						}
					}
				}
			}
			classes: allSanityClass {
				edges {
					node {
						id
						name
						description {
							sanityChildren {
								text
							}
						}
					}
				}
			}
			pricing: sanityPricingPage {
				_rawPricing
			}
		}
	`)

	const contentRef = useRef(null)
	const [animateIn, setAnimateIn] = useState(false)

	const { groupSchedule, site } = data
	const { landingPageImage } = site
	const { schedule } = groupSchedule.edges[0].node
	const pricing = data.pricing._rawPricing

	useEffect(() => {
		function doWork() {
			let vh = window.innerHeight * 0.01
			document.documentElement.style.setProperty("--vh", `${vh}px`)
		}
		doWork()
		window.addEventListener("resize", doWork)
		return () => {
			window.removeEventListener("resize", doWork)
		}
	}, [])

	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	useEffect(() => {
		setAnimateIn(true)
	}, [])

	return (
		<>
			<SEO url="/" />
			<h1 hidden>Welcome to {site.title}</h1>
			<HeroImage
				height="100vh"
				base64={landingPageImage.asset.mainImage.base64}
				aspectRatio={landingPageImage.asset.mainImage.aspectRatio}
				imageAlign="top"
				imageAlt={landingPageImage.alt}
				imageSrc={landingPageImage.asset.mainImage.src}
				contentRef={contentRef}
			>
				{({ scrollToContent }) => (
					<div className={styles.heroContainer}>
						<Section className={styles.heroSection}>
							<Container breakpoint="fullhd">
								<div className={cn(styles.heroText, animateIn && styles.animateIn)}>
									{site.description && (
										<Heading size={5} renderAs="p" className={styles.scrollToContent}>
											{site.description}
										</Heading>
									)}
									<Button size="large" variant="solidwhite" onClick={scrollToContent}>
										Find out more
									</Button>
								</div>
							</Container>
						</Section>
					</div>
				)}
			</HeroImage>
			<div ref={contentRef}>
				<Section className={styles.schedule}>
					<Container breakpoint="fullhd">
						<Heading size={3} renderAs="h2">
							Schedule
						</Heading>
						{days.map((day) => (
							<div className={styles.scheduleDay} key={day}>
								<ScheduleDay day={day} items={getScheduleItemsOfDay(schedule, day)} />
							</div>
						))}
					</Container>
				</Section>
				<Section className={styles.pricing}>
					<Container breakpoint="fullhd">
						<Heading size={3} renderAs="h2">
							Pricing
						</Heading>
						<div className={styles.pricingTable}>
							<Heading size={6} renderAs="p" className={styles.pricingHeading}>
								Class
							</Heading>
							<Heading size={6} renderAs="p" className={styles.pricingHeadingMiddle}>
								Info
							</Heading>
							<Heading size={6} renderAs="p" className={styles.pricingHeadingLast}>
								Price
							</Heading>
							{pricing.map((e) => (
								<Fragment key={e._key}>
									<p className={styles.pricingItem}>{e.className}</p>
									<p className={styles.pricingItemMiddle}>{e.info ? e.info : ""}</p>
									<p className={styles.pricingItemLast}>{e.price}</p>
								</Fragment>
							))}
						</div>
					</Container>
				</Section>
			</div>
		</>
	)
}

export default IndexPage
